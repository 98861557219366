/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { lazy, Suspense } from "react";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import ScrollToTop from "sharedComponents/ScrollToTop";
import { Layout } from "sharedComponents/Layout";
import Loader from "sharedComponents/Loader";
import { LoginPage } from "pages/guest/loginPage";
import { LayoutProvider } from "context/layoutContext";
import OfflinePage from "pages/guest/_offlinePage";
import PrivateRoute from "sharedComponents/PrivateRoute";
const DashboardRoutes = lazy(() => import("routes/dashboardRoutes"));

function App() {
  return (
    <LayoutProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route element={<Layout>{<Outlet />}</Layout>}>
              <Route path="/offline" element={<OfflinePage />} />
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <DashboardRoutes />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <DashboardRoutes />
                </PrivateRoute>
              }
            />
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            toastClassName="px-2 shadow-sm rounded-md text-lg"
          />
        </Suspense>
      </BrowserRouter>
    </LayoutProvider>
  );
}

export default App;
