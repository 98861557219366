import { gql } from "@apollo/client";

export const DashbaordAnalyticsRequest = gql`
  query adminDashboardAnalytics {
    adminDashboardAnalytics {
      status
      message
      data {
        revenue
        totalUsers
        totalProduct
        totalOrders
        totalLookBooks
      }
    }
  }
`;

// subscriptions
export const POST_CREATED = gql`
  subscription postCreated {
    postCreated {
      id
      author
      comment
      createdAt
      status
    }
  }
`;

export const NOTIFICATION_CREATED = gql`
  subscription notificationCreated {
    notificationCreated {
      createdAt
      id
      isRead
      order {
        customerEmail
        id
      }
    }
  }
`;

export const getAllNotifications = gql`
  query getAllNotifications($limit: Int, $offset: Int) {
    getAllNotifications(limit: $limit, offset: $offset) {
      status
      message
      data {
        createdAt
        isRead
        id
        order {
          customerEmail
          id
        }
      }
    }
  }
`;

export const getAllPosts = gql`
  query getPosts($limit: Int, $offset: Int) {
    getPosts(limit: $limit, offset: $offset) {
      status
      message
      totalCount
      data {
        id
        author
        comment
        createdAt
        status
      }
    }
  }
`;

export const updateNotificationStatus = gql`
  mutation updateNotificationStatus($id: ID!) {
    updateNotificationStatus(id: $id) {
      status
    }
  }
`;
