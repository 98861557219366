import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { GQLMutate, GQLQuery } from "services/gql-client";
import { handleGeneralErrors } from "utilities/handleGeneralErrors";

function useNetworkData() {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMutating, setIsMutating] = useState(false);
  const [error, setError] = useState();

  const getData = useCallback(
    async ({ payload, requestName, apiRequest, returnError }) => {
      setIsLoading(true);
      return GQLQuery(requestName, apiRequest, payload)
        .then((res) => {
          setResponse(res.data);
          if (res.status === "error") {
            toast.error(res.message);
            return;
          }
          return res;
        })
        .catch((err) => {
          console.log({ resp: err });
          setIsLoading(false);
          if (returnError) {
            setError(err);
          } else {
            handleGeneralErrors(err);
          }
        })
        .finally(() => setIsLoading(false));
    },
    []
  );

  const mutateData = useCallback(
    async ({ payload, requestName, apiRequest, returnError }) => {
      setIsMutating(true);
      return GQLMutate(requestName, apiRequest, payload)
        .then((data) => {
          if (data?.status === "error") {
            toast.error(data.message);
          }
          setResponse(data);
          return data;
        })
        .catch((err) => {
          // console.log({ err });
          setIsMutating(false);
          if (returnError) {
            setError(err);
          } else {
            handleGeneralErrors(err);
          }
        })
        .finally(() => setIsMutating(false));
    },
    []
  );
  return { isLoading, isMutating, getData, mutateData, response, error };
}

export default useNetworkData;
