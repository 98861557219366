import React, { useEffect, useState } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./sidebar.scope.scss";
import logo from "assets/images/otito-logo-white.svg";
import { logoutService } from "services/network/auth";

export const Sidebar = () => {
  const [isOpen, setisOpen] = useState(false);
  const [showSubLink, setShowSubLink] = useState("");
  const toggleSidebar = () => {
    setisOpen(!isOpen);
  };
  const productUrl = useMatch({ path: "/products/*" });
  useEffect(() => {
    if (productUrl) {
      setShowSubLink("product");
    } else {
      setShowSubLink("");
    }
  }, [productUrl]);

  return (
    <>
      <div>
        <div className="menu-icon" onClick={toggleSidebar}>
          <Icon icon="grommet-icons:menu" />
        </div>
        <div
          className={`sidebar-overlay ${isOpen && "overlay-isOpen"}`}
          onClick={toggleSidebar}
        >
          <div className="closeMenu">
            <Icon icon="clarity:times-line" />
          </div>
        </div>

        <section className={`sidebar ${isOpen && "sidebar-isOpen"}`}>
          <div className="sidebar-wrapper">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <ul className="top-list">
              <li>
                <NavLink
                  className="side-link"
                  to="/dashboard"
                  onClick={toggleSidebar}
                >
                  <span className="side-icon">
                    <Icon icon="ps:compass" />
                  </span>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="side-link"
                  to="/orders"
                  onClick={toggleSidebar}
                >
                  <span className="side-icon">
                    <Icon icon="heroicons:shopping-cart" />
                  </span>
                  <span>Orders</span>
                </NavLink>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (showSubLink === "product") {
                      setShowSubLink("");
                    } else {
                      setShowSubLink("product");
                    }
                  }}
                  className={`side-link ${
                    showSubLink === "product" ? "active" : ""
                  }`}
                >
                  <span className="side-icon">
                    <Icon icon="akar-icons:shipping-box-02" />
                  </span>
                  <span>Products</span>
                </div>
                <div
                  className={`sub-links ${
                    showSubLink === "product" ? "show" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <NavLink
                        to="/products"
                        className="side-link"
                        onClick={toggleSidebar}
                        end
                      >
                        <span>Products</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="side-link"
                        to="/products/update-category"
                        onClick={toggleSidebar}
                      >
                        <span>Categories</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="side-link"
                        to="/products/update-tag"
                        onClick={toggleSidebar}
                      >
                        <span>Tags</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="side-link"
                        to="/products/update-brand"
                        onClick={toggleSidebar}
                      >
                        <span>Brands</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <NavLink
                  className="side-link"
                  to="/lookbooks"
                  onClick={toggleSidebar}
                >
                  <span className="side-icon">
                    <Icon icon="akar-icons:dashboard" />
                  </span>
                  <span>Lookbooks</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="side-link"
                  to="/users"
                  onClick={toggleSidebar}
                >
                  <span className="side-icon">
                    <Icon icon="bx:user" />
                  </span>
                  <span>Users</span>
                </NavLink>
              </li>
            </ul>

            <ul className="mt-auto mb-5">
              <li>
                <div className="side-link click">
                  <span className="side-icon">
                    <Icon icon="akar-icons:dashboard" />
                  </span>
                  <span onClick={() => logoutService()}>Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};
