import React, { ReactNode } from "react";
import { Sidebar } from "sharedComponents/sidebar";
import { Navbar } from "sharedComponents/navbar";
import "./styles.scss";

export const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <section className="layout-wrapper">
      <Sidebar />
      <div className="content-wrapper">
        <Navbar />
        <div className="my-5">{children}</div>
      </div>
    </section>
  );
};
