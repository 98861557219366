import { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { Icon } from "@iconify/react";
import profile from "assets/images/Traditional attire 2.png";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { LayoutContext } from "context/layoutContext";
import { getStorageData, StorageKeys } from "lib/storageManager";
import { Notifications } from "./notifications";

const Search = ({
  inputValue,
  searchData,
}: {
  inputValue: string;
  searchData: Function;
}) => {
  return (
    <InputGroup className="form-control">
      <InputGroup.Text className="bg-transparent border-0 text-lg">
        <Icon icon="akar-icons:search" className="text-grey-300" />
      </InputGroup.Text>
      <Form.Control
        value={inputValue}
        className="border-0"
        type="search"
        aria-label="search"
        placeholder="Search transactions, products.."
        onChange={(e) => searchData(e)}
      />
    </InputGroup>
  );
};
export const Navbar = () => {
  const location = useLocation();
  const {
    title,
    hideSearchBar,
    setSearchValue,
    notifications,
    setNotifications,
    filteredData,
    slicedNotification,
  }: any = useContext(LayoutContext);
  const [timer, setTimer] = useState<string | number | any>(null);
  const [inputValue, setInputValue] = useState("");
  const user = getStorageData(StorageKeys.user);
  const searchData = ({ target: { value } }: any) => {
    setInputValue(value);
    clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      setSearchValue(value);
    }, 500);
    setTimer(newTimer);
  };

  useEffect(() => {
    setSearchValue("");
    setInputValue("");
  }, [location, setSearchValue]);

  return (
    <>
      <div className="container">
        <nav className="nav d-flex justify-content-between align-items-center mb-4">
          <div className="d-md-block d-none">{title}</div>
          {hideSearchBar === false && (
            <div className="nav-search d-md-block d-none">
              <Search searchData={searchData} inputValue={inputValue} />
            </div>
          )}

          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-5">
              <Link to="/settings" className="text-grey-300">
                <Icon icon="bi:gear" className="text-xl me-3 click" />
              </Link>

              <Notifications
                notifications={notifications}
                setNotifications={setNotifications}
                slicedNotification={slicedNotification}
                filteredData={filteredData}
              />
            </div>
            <Link to="/profile" className="text-decoration-none">
              <div className="user-profile-wrapper">
                <div className="text-black-500">
                  <img src={profile} alt="" />
                </div>
                <p className="mb-0 fw-semi-bold text-sm text-black-500">
                  {user?.fullName}
                </p>
              </div>
            </Link>
          </div>
        </nav>
        <div className="mb-4">
          <div className="d-md-none d-block mt-5 mb-4">{title}</div>
          {hideSearchBar === false && (
            <div className="nav-search d-md-none d-block">
              <Search searchData={searchData} inputValue={inputValue} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
