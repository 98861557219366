import React, { useEffect } from "react";
import offline from "assets/images/Offline.gif";
import { useNavigate, useLocation } from "react-router";
import "./styles.scss";

const OfflinePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || {
    from: { pathname: "/" },
  };
  const online = navigator.onLine;

  const retry = () => {
    navigate(from);
  };

  useEffect(() => {
    if (online) {
      retry();
    }
  }, [online]);

  return (
    <div className="notFound_page">
      <img src={offline} alt="" />
      <p>
        Oops...!!!  <br /> It seem you are not connected to the internet. <br /> Please
        check your connection and try again
      </p>
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary-200 btn-lg px-5" onClick={retry}>
          Retry
        </button>
      </div>
    </div>
  );
};

export default OfflinePage;
