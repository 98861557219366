import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/otito-logo-blk.svg";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import "./style.scss";
import { LoginRequest } from "services/network/auth";
import { handleGeneralErrors } from "utilities/handleGeneralErrors";
import Button from "sharedComponents/forms/Button";
import TextInput from "sharedComponents/forms/TextInput";
import { saveStorageData, StorageKeys } from "lib/storageManager";
import { GQLMutate } from "services/gql-client";
import PasswordInput from "sharedComponents/forms/PasswordInput";
import { toast } from "react-toastify";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    emailAddress: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    emailAddress: yup.string().label("Email Address").required(),
    password: yup.string().label("Password").required(),
  });

  const handleLogin = async (formValues) => {
    setLoading(true);
    GQLMutate("loginUser", LoginRequest, formValues)
      .then(({ token, data, status }) => {
        saveStorageData(StorageKeys.token, token);
        saveStorageData(StorageKeys.user, data);

        if (status === "success") {
          if (data.isAdmin) {
            navigate("/dashboard");
          } else {
            toast.error("User not authorized!");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        handleGeneralErrors(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <section className="container login-wrapper py-6">
        <div className="row my-auto">
          <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto">
            <div className="border px-5 pt-6 pb-9">
              <div className="text-center">
                <img src={logo} alt="logo" />
              </div>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {({ handleSubmit, isValid }) => {
                  return (
                    <>
                      <Form onSubmit={handleSubmit} className="form-wrapper">
                        <Field
                          component={TextInput}
                          name="emailAddress"
                          id="emailAddress"
                          type="email"
                          className="mt-4"
                          placeholder="Email address"
                        />
                        <Field
                          component={PasswordInput}
                          name="password"
                          id="password"
                          type="password"
                          autoComplete="on"
                          className="mt-4"
                          placeholder="Password"
                        />

                        <Button
                          className="btn btn-black btn-lg w-100 mt-5"
                          type="submit"
                          title={"Sign in"}
                          loading={loading}
                          loadingTitle={"Please wait..."}
                          disabled={!isValid || loading}
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <p className="text-lg fw-medium mt-auto text-center">
          © {new Date().getFullYear()} Otitolab. All rights reserved.
        </p>
      </section>
    </>
  );
};
