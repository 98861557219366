export const StorageKeys = {
  token: "otito_admin_token",
  user: "otito_admin",
};

export function getStorageData(key: string) {
  try {
    const rawUserData = localStorage.getItem(key);
    if (!rawUserData) {
      return null;
    }

    return JSON.parse(rawUserData);
  } catch (e) {
    // TODO: log to error tool
    return null;
  }
}

export function saveStorageData(key: string, value: any) {
  try {
    if (!value || !key) {
      return;
    }

    const stringifyValue = JSON.stringify(value);
    localStorage.setItem(key, stringifyValue);
  } catch (e) {
    // TODO: log to error tool
    return null;
  }
}

export function removeStorageData(key: string) {
  try {
    if (!key) {
      return;
    }

    localStorage.removeItem(key);
  } catch (e) {
    // TODO: log to error tool
    return null;
  }
}
