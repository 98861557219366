import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useSubscription } from "@apollo/client";
import { getAllNotifications } from "services/network/dashboard";
import useNetworkData from "hooks/useNetworkData";
import { NOTIFICATION_CREATED } from "services/network/dashboard";

export const LayoutContext: any = createContext(null);

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState(
    <h3 className="fw-semi-bold text-2xl text-black-500 d-md-block d-none">
      Dashboard
    </h3>
  );
  const { data, loading } = useSubscription(NOTIFICATION_CREATED);
  const [hideSearchBar, setHideSearchBar] = useState(false);
  const { getData } = useNetworkData();
  const [searchValue, setSearchValue] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagingInfo, setPagingInfo] = useState({
    limit: 10,
    offset: 0,
  });

  const fetchNotifications = useCallback(async () => {
    const payload = { ...pagingInfo };
    const res = await getData({
      requestName: "getAllNotifications",
      apiRequest: getAllNotifications,
      returnError: true,
      payload,
    });

    if (res) {
      setNotifications(res.data);
      setTotalCount(res.totalCount);
    }
  }, [getData, pagingInfo]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const slicedNotification = notifications.slice(0, 4);
  const filteredData =
    notifications.length > 0 &&
    notifications.filter((item: any) => item?.isRead === false);
  return (
    <LayoutContext.Provider
      value={{
        title,
        setTitle,
        hideSearchBar,
        setHideSearchBar,
        searchValue,
        setSearchValue,
        fetchNotifications,
        notifications,
        setNotifications,
        totalCount,
        pagingInfo,
        setPagingInfo,
        data,
        loading,
        slicedNotification,
        filteredData,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
