import { Icon } from "@iconify/react";
import { useContext, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { LayoutContext } from "context/layoutContext";
import { CustomDropdwonItem } from "sharedComponents/CustomDropdwonItem";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { notificationProps } from "services/network/_schema";

export const Notifications = ({
  setNotifications,
  notifications,
  slicedNotification,
  filteredData,
}: notificationProps) => {
  const { loading, data }: { loading: boolean; data: any } =
    useContext(LayoutContext);

  useEffect(() => {
    if (!loading) {
      setNotifications((prev: any) => {
        const foundData = prev.find((post: any, id: number) => {
          return post?.id === data?.notificationCreated?.id;
        });
        return foundData ? [...prev] : [data?.notificationCreated, ...prev];
      });
    }
  }, [loading, data, setNotifications]);

  return (
    <>
      <Dropdown className="d-inline mx-2" autoClose="outside">
        <Dropdown.Toggle
          id="dropdown-toggle"
          className={`no-toggle ${filteredData.length > 0 && " dot"}`}
        >
          <Icon icon="gala:bell" className="text-xl click " />
        </Dropdown.Toggle>
        <Dropdown.Menu className="fw-medium fw-custom-dp">
          <Dropdown.Item className="border-bottom customhover">
            <div className="text-black-500 text-lg fw-semi-bold py-2">
              Notifications
            </div>
          </Dropdown.Item>

          <Dropdown.Item className={`py-3 customhover`} as={CustomDropdwonItem}>
            {notifications.length > 0 ? (
              slicedNotification.map((notifications: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={`order-wrapper border-bottom border-2 ${
                      !notifications?.isRead && "unread"
                    }`}
                  >
                    <p
                      className={`fw-semi-bold text-sm ${
                        !notifications?.isRead && "custom-dot"
                      }`}
                    >
                      New order alert
                    </p>

                    <div className="d-flex justify-content-between align-items-center gap-custom">
                      <p className="fw-medium text-xs text-grey-300 truncate">
                        “A new order created by{" "}
                        {notifications?.order.customerEmail}.”
                      </p>

                      <p className="text-grey-100 text-2xs text-capitalize">
                        {DateTime.fromJSDate(
                          new Date(notifications?.createdAt)
                        ).toRelativeCalendar()}
                        ,{" "}
                        {DateTime.fromJSDate(
                          new Date(notifications?.createdAt)
                        ).toLocaleString(DateTime.TIME_SIMPLE)}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="d-flex justify-content-between">
                <p className="fw-semi-bold text-sm">
                  No notifications available.
                </p>
              </div>
            )}
          </Dropdown.Item>

          <div className="pe-3 d-flex justify-content-end">
            <Link
              to="/notifications"
              className="text-capitalize text-primary-500 text-sm text-capitalize fw-semibold see-all"
            >
              see all
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
