import { getStorageData, StorageKeys } from "lib/storageManager";
import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const token = getStorageData(StorageKeys.token)
  // const token: string = "token here";
  const online = navigator.onLine;

  return !online ? (
    <Navigate to="/offline" state={{ from: location }} />
  ) : token ? (
    children
  ) : (
    <Navigate replace to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;
