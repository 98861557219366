import React from "react";

const Button = ({
  className,
  icon: IconComponent,
  title,
  loadingTitle,
  loading,
  ...props
}: {
  className?: string;
  icon?: any;
  title: string;
  loadingTitle?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: any;
}) => (
  <button
    type="submit"
    className={`btn ${className || "btn-primary btn-block"}`}
    {...props}
  >
    {loading ? (
      <div className="spinner-border spinner-border-sm me-2" />
    ) : IconComponent ? (
      <IconComponent />
    ) : null}
    {loading ? loadingTitle || title : title}
  </button>
);

export default Button;
