import { toast } from "react-toastify";
import { logoutService } from "services/network/auth";

export const handleGeneralErrors = (err) => {
  if (err.graphQLErrors) {
    if (err.graphQLErrors.length > 0) {
      err.graphQLErrors.forEach((err) => {
        if (
          err.message === "Authorization header must be provided" ||
          err.message === "Invalid/Expired token"
        ) {
          toast.error("Access Denied!");
          logoutService();
        } else {
          toast.error(err.message);
        }
      });
    } else {
      toast.error(err.message || JSON.stringify(err));
    }
  } else {
    toast.error("Sorry, an error occurred!");
  }
};
