import { gql } from "@apollo/client";
import { removeStorageData, StorageKeys } from "lib/storageManager";

export const LoginRequest = gql`
  mutation loginUser($password: String!, $emailAddress: String!) {
    loginUser(password: $password, emailAddress: $emailAddress) {
      token
      status
      data {
        id
        fullName
        emailAddress
        profileImage
        phoneNumber
        isAdmin
        isActive
      }
    }
  }
`;

export const updatePasswordRequest = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      status
      message
    }
  }
`;

export const updateProfileRequest = gql`
  mutation updateProfile(
    $lastName: String!
    $firstName: String!
    $email: String!
  ) {
    updateProfile(lastName: $lastName, firstName: $firstName, email: $email) {
      status
      message
    }
  }
`;

//logout
export const logoutService = () => {
  removeStorageData(StorageKeys.token);
  window.location.replace("/login");
};
